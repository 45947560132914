<template>
  <!-- 自营产品头部 -->
  <div class="category-root">
    <bread-nav></bread-nav>
    <div class="newCategory" v-loading="loading">
      <div class="filter">
        <div class="filter-row">
          <div class="filter-name">
            <img
                src="~img/alibaba/category.png">分类
          </div>
          <div :class="`filter-list ${category_more ? 'activited' : ''}`">
						<span :class="`filter-item ${category_id == 0 ? 'activited' : ''}`"
                  @click="setAttr(0, 'category_id')">全部</span>
            <span :class="`filter-item ${category_id == item.category_id ? 'activited' : ''}`"
                  v-for="(item, index) in catMenu.category"
                  :key="index" @click="setAttr(item.category_id, 'category_id')">{{ item.category_name }}</span>
          </div>
          <span class="more" @click="switchMore('category')">
						{{ category_more ? '收起' : '更多' }}
						<img src="~img/alibaba/top.png" v-if="category_more">
						<img src="~img/alibaba/bottom.png" v-if="!category_more">
					</span>
        </div>
        <div class="filter-row">
          <div class="filter-name">
            <img
                src="~img/alibaba/brand.png">品牌
          </div>
          <div :class="`filter-list ${brand_more ? 'activited' : ''}`">
						<span :class="`filter-item ${brand_id == 0 ? 'activited' : ''}`"
                  @click="setAttr(0, 'brand_id')">全部</span>
            <span :class="`filter-item ${brand_id == item.brand_id ? 'activited' : ''}`"
                  v-for="(item, index) in catMenu.brand"
                  :key="index" @click="setAttr(item.brand_id, 'brand_id')">{{ item.brand_name }}<span
                class="font_color">({{ item.goods_num }})</span></span>
          </div>
          <span class="more" @click="switchMore('brand')">
						{{ brand_more ? '收起' : '更多' }}
						<img src="~img/alibaba/top.png" v-if="brand_more">
						<img src="~img/alibaba/bottom.png" v-if="!brand_more">
					</span>
        </div>
        <div class="filter-row">
          <div class="filter-name">
            <img
                src="~img/alibaba/origin.png">产地
          </div>
          <div :class="`filter-list ${origin_more ? 'activited' : ''}`">
						<span :class="`filter-item ${origin == 0 ? 'activited' : ''}`"
                  @click="setAttr(0, 'origin')">全部</span>
            <span :class="`filter-item ${origin == item ? 'activited' : ''}`"
                  v-for="(item, index) in catMenu.origin"
                  :key="index" @click="setAttr(item, 'origin')">{{ item }}</span>
          </div>
          <span class="more" @click="switchMore('origin')">
						{{ origin_more ? '收起' : '更多' }}
						<img src="~img/alibaba/top.png" v-if="origin_more">
						<img src="~img/alibaba/bottom.png" v-if="!origin_more">
					</span>
        </div>
      </div>

      <div class="filter order">
        <!-- 自营产品 -->
        <div class="order-left">
          排序：
          <span
              :class="`order-tag ${orderType == 'create_time' ? 'activited' : ''} ${orderSort == 'asc' ? 'asc' : ''}`"
              @click="setOrderSort('create_time')">最新</span>
          <span
              :class="`order-tag ${orderType == 'price' ? 'activited' : ''} ${orderSort == 'asc' ? 'asc' : ''}`"
              @click="setOrderSort('discount_price')">价格</span>
          <div class="order-price-range">
            <div class="order-price min">
              ￥<input type="number" min="0" v-model="minPrice" @input="loading=true; getGoodsSkuPage()">
            </div>
            -
            <div class="order-price max">
              ￥<input type="number" min="0" v-model="maxPrice" @input="loading=true; getGoodsSkuPage()">
            </div>
          </div>
        </div>
        <div class="order-right">
          <div class="search-box">
            <input type="text" v-model="keyword" placeholder="请输入您要搜索的商品">
            <el-button @click="loading=true; getGoodsSkuPage()">搜索</el-button>
          </div>
          共<span>{{ goodsList.count }}</span>件商品
        </div>
      </div>
      <div class="goods-list">
        <goods-list :data="goodsList" :pagesize="pageSize" :page="page" @syncGoodsList="syncGoodsList"></goods-list>
      </div>
    </div>
  </div>
</template>

<script>
import {screen} from "@/api/goods/goodscategory"
import {goodsSkuPage} from '@/api/goods/goods'
import GoodsList from '@/layout/components/goods-list'
import BreadNav from '@/layout/components/bread-nav'

export default {
  name: "category",
  components: {
    GoodsList,
    BreadNav,
  },
  data: () => {
    return {
      goodsCategory: [],
      categoryFixed: false,
      clickIndex: 0,
      loading: true,
      category_more: false,
      brand_more: false,
      origin_more: false,
      category_id: 0,
      category_level: 0,
      brand_id: 0,
      origin: 0,
      catMenu: {},
      goodsList: {},
      orderType: null,
      orderSort: null,
      minPrice: '',
      maxPrice: '',
      pageSize: 36,
      page: 1,
      self_support:0
    }
  },
  created() {
    this.category_id = this.$route.query.category_id || 0
    if (this.$route.query.parent_id && this.$route.query.parent_id>0) {
      this.category_id = this.$route.query.parent_id
    }
    this.brand_id = this.$route.query.brand_id || 0
    this.origin = this.$route.query.origin || 0
    this.keyword = this.$route.query.keyword || ''
    this.self_support = this.$route.query.self_support || 0
    this.category_level = this.$route.query.level || 0
    this.getGoodsCategory()
    this.getGoodsSkuPage()
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  watch: {
    $route() {
      if (this.$route.query.keyword != this.keyword) {
        this.keyword = this.$route.query.keyword || ''
        this.getGoodsCategory()
        this.getGoodsSkuPage()
      }
    }
  },
  methods: {
    syncFilter(e) {
      this.category_id = e.category_id
      this.brand_id = e.brand_id
      this.origin = e.origin
      this.orderType = e.orderType
      this.orderSort = e.orderSort
      this.minPrice = e.minPrice
      this.maxPrice = e.maxPrice
      this.keyword = e.keyword
      this.loading = true
      this.getGoodsSkuPage()
    },
    // 分类列表
    getGoodsCategory() {
      screen({
        template: 1,
        level: 1,
        isself: 1,
      }, 0, 'GET')
          .then(res => {
            if (res.code == 0) {
              this.catMenu = res.data
            }
            this.loading = false
          })
          .catch(err => {
            this.$message.error(err.message)
            this.loading = false
          })
    },

    // kantuxiadan
    getGoodsCategory1() {
      screen1({
        template: 1,
        level: 1
      }, 0, 'GET')
          .then(res => {
            if (res.code == 0) {
              this.catMenu = res.data
            }
            this.loading = false
          })
          .catch(err => {
            this.$message.error(err.message)
            this.loading = false
          })
    },
    getGoodsSkuPage() {
      goodsSkuPage({
        brand_id: this.brand_id,
        origin: this.origin,
        category_id: this.category_id,
        order: this.orderType || '',
        sort: this.orderSort || '',
        keyword: this.keyword || '',
        self_support: this.self_support,
        min_price: this.minPrice,
        max_price: this.maxPrice,
        page_size: this.pageSize,
        page: this.page
      }, 0, 'GET').then(res => {
        if (res.code == 0) {
          this.goodsList = res.data
          // console.log(typeof res.data)
          console.log(this.goodsList)
        }
        this.loading = false
      })
          .catch(err => {
            this.$message.error(err.message)
            this.loading = false
          })
    },
    switchMore(event) {
      this[`${event}_more`] = !this[`${event}_more`]
    },
    setAttr(value, event) {
      this.loading = true
      this[event] = value
      this.getGoodsSkuPage()
    },
    setOrderSort(orderType) {
      this.orderType = orderType
      console.log('orderType:' + orderType)
      if (this.orderSort == 'desc') {
        this.orderSort = 'asc'
      } else if (this.orderSort == 'asc') {
        this.orderSort = 'desc'
      } else {
        this.orderSort = 'desc'
      }
      this.loading = true
      this.getGoodsSkuPage()
    },
    syncGoodsList(e) {
      this.page = e
      this.getGoodsSkuPage()
    }
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener("scroll", this.handleScroll)
  }
}
</script>
<style lang="scss" scoped>
.category-root {
  padding-top: 30px;
}

.newCategory {
  padding: 30px 0;
  width: $width;
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;

  .goods-list {
    margin-top: 50px;
  }

  .filter {
    border: 1px solid #d9d9d9;
    border-right: 0;

    .filter-row {
      height: 100%;
      display: flex;
      align-items: flex-start;
      position: relative;
      border-bottom: 1px dashed #d9d9d9;

      &:last-child {
        border: none;
      }

      .filter-name {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $base-color-gray;
        color: $ns-text-color-gray;
        font-size: $ns-font-size-lg;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 1390px;

        img {
          width: 14px;
          height: 14px;
          margin-right: 3px;
        }
      }

      .filter-list {
        width: 1200px;
        padding-right: 80px;
        margin-left: 120px;
        max-height: 50px;
        overflow: hidden;

        &.activited {
          max-height: 1000px;
        }

        .filter-item {
          height: 26px;
          line-height: 26px;
          display: inline-block;
          color: $ns-text-color-black;
          font-size: $ns-font-size-lg;
          padding: 0 4px;
          margin: 12px;
          border-radius: 5px;
          background-color: transparent;
          transition: all .3s;
          cursor: pointer;
          user-select: none;
          vertical-align: top;

          &.activited, &:hover {
            background-color: $base-color;
            color: #ffffff;
          }
        }
      }
    }

    .more {
      position: absolute;
      right: 20px;
      top: 11px;
      font-size: $ns-font-size-lg;
      color: $ns-text-color-gray;
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;

      img {
        width: 10px;
        height: 10px;
        margin-left: 3px;
      }
    }

    &.order {
      height: 80px;
      margin-top: 20px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 20px;

      .order-left {
        display: flex;
        align-items: center;
        font-size: $ns-font-size-lg;
        color: $ns-text-color-black;

        .order-tag {
          height: 26px;
          line-height: 26px;
          display: inline-block;
          color: $ns-text-color-black;
          font-size: $ns-font-size-lg;
          padding: 0 10px;
          border-radius: 5px;
          margin-right: 4px;
          cursor: pointer;
          user-select: none;

          &:after {
            display: inline-block;
            content: '';
            transform: rotate(45deg);
            width: 0;
            height: 0;
            border: 4px solid;
            border-color: transparent $base-color-info $base-color-info transparent;
            vertical-align: 3px;
            margin-left: 5px;

            .asc {
              &:after {
                border-color: #ffffff transparent transparent #ffffff;
                vertical-align: -3px;
              }
            }
          }

          &.activited, &:hover {
            background-color: $base-color;
            color: #ffffff;

            &:after {
              border-color: transparent #ffffff #ffffff transparent;
            }

            &.asc {
              &:after {
                border-color: #ffffff transparent transparent #ffffff;
                vertical-align: -3px;
              }
            }
          }


        }

        .order-price-range {
          display: flex;
          align-items: center;

          .order-price {
            border: 1px solid #d9d9d9;
            height: 25px;
            width: 60px;
            display: flex;
            align-items: center;
            color: $ns-text-color-black;
            font-size: $ns-font-size-base;
            margin: 0 5px;

            input {
              border: none;
              outline: none;
              height: 20px;
              font-size: $ns-font-size-base;
              color: $ns-text-color-black;
              width: 40px;
            }
          }
        }
      }

      .order-right {
        font-size: $ns-font-size-lg;
        color: $ns-text-color-gray;
        display: flex;
        align-items: center;

        > span {
          color: $base-color;
          margin: 0 5px
        }

        .search-box {
          display: flex;
          align-items: center;
          margin-right: 15px;

          input {
            border: 1px solid $base-color-gray;
            width: 300px;
            height: 40px;
            box-sizing: border-box;
            padding: 0 12px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            outline: none;
          }

          .el-button {
            height: 40px;
            width: 70px;
            background-color: $base-color;
            font-size: $ns-font-size-base;
            color: #ffffff;
            text-align: center;
            border-radius: 0px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }
      }
    }
  }
}

.font_color {
  color: #666;
}
</style>
